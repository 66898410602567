import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { injectStyle } from "react-toastify/dist/inject-style";
import Stack from "react-bootstrap/Stack";
import background from "../img/background.png";
import list from "./img/list.svg";
import transparentAnimation from "./img/transparent_animation.gif";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../img/logo_f2.svg";
import img1 from "./img/img1.png";
import img2 from "./img/img2.png";
import img3 from "./img/img3.png";
import icon7 from "./img/game.png";
import Row from "react-bootstrap/Row";
import cube from "./img/cube-min.svg";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import Helvetica from "./fonts/HelveticaNeue.ttf";
import HelveticaBold from "./fonts/HelveticaNeueBold.ttf";
import HelveticaBlack from "./fonts/HelveticaNeueCondensedBlack.ttf";
import { useTranslation } from "react-i18next";
import "../../i18n";
import dropdownCaret from "./dropdown-caret.svg";
import languageIcon from "./language-icon.svg";

if (typeof window !== "undefined") {
    injectStyle();
}

export default function LandingPage() {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get("lang");
    console.log("🚀 ~ LandingPage ~ lang:", lang);
    const { t, i18n } = useTranslation();
    const projectHost = window.location.host.toString().split(":");
    const hostlink = projectHost[1]
        ? window.location.host
        : window.location.hostname;

    const [showCookPolicy, setShowCookiePolicy] = useState(false);
    const [showPrivPolicy, setShowPrivacyPolicy] = useState(false);
    const [showModalContent, setShowModalContent] = useState(false);
    const [loading, showLoading] = useState(false);
    const [modalHeading, setModalHeading] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [show, setShow] = useState(false);
    const [showGame, setShowGame] = useState(false);
    const [gameSelected, setGameSelected] = useState(0);
    const [gameIds, _] = useState([
        "7a16aca9-7567-4b01-9113-a181b82fde2d",
        "0fd8e779-37d8-4269-9ea6-71adc9920e77",
        "e5ef36a4-697d-4705-9767-02d5cf93ee71",
        "45bf76cc-89b3-43aa-927b-3b441fe6d2fb",
        "62ec720b-dc21-4c73-ab8a-df4b7dfbebf3",
        "43af4a57-4c28-4c41-bff8-5b4be9553a91",
    ]);

    useEffect(() => {
        if (!!i18n && lang) {
            i18n.changeLanguage(lang);
        }
    }, [i18n, lang]);

    const handleClose = () => {
        setShowCookiePolicy(false);
        setShowPrivacyPolicy(false);
        setShowModalContent(false);
        setShow(false);
        showLoading(false);
    };
    const handleShow = () => setShow(true);

    const handleCloseGame = () => {
        setShowGame(false);
        showLoading(false);
    };
    const handleShowGame = (event) => {
        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
        const centerThreshold = 110;
        const distance = Math.sqrt(
            Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2)
        );
        const angle = Math.atan2(y - centerY, x - centerX) + Math.PI;
        const vertexIndex = Math.floor(angle / (Math.PI / 3)); // vertex will be [0-5]
        if (distance < centerThreshold) {
            // console.log('Click in center')
        } else {
            // console.log(vertexIndex);
            setGameSelected(vertexIndex);
            showLoading(true);
            setShowGame(true);
        }
    };

    const showCookiePolicy = () => {
        setModalHeading("Cookie Policy");
        setShowCookiePolicy(true);
        handleShow();
        showLoading(true);
    };

    const showPrivacyPolicy = () => {
        setModalHeading("Privacy Policy");
        setShowPrivacyPolicy(true);
        handleShow();
        showLoading(true);
    };

    const scrollTo = (elementId, atTop) => {
        let section = document.querySelector(`#${elementId}`);
        let position = "center";
        if (atTop) {
            position = "start";
        }
        section.scrollIntoView({ behavior: "smooth", block: `${position}` });
    };

    const handleLanguageChange = (language) => {
        // setCurrentLang(language);
        i18n.changeLanguage(language);

        const url = new URL(window.location);
        url.searchParams.set("lang", language);
        window.history.pushState({}, "", url);
    };

    return (
        <>
            <link
                href="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/5.0.0/mdb.min.css"
                rel="stylesheet"
            />
            <link
                href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
                rel="stylesheet"
            />
            <link
                href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"
                rel="stylesheet"
            />

            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js"></script>
            <style>
                {`
                .language-icon {
    height: 1.5rem;
    color:#fff;
    vertical-align: middle;
}

.language-caret {
color:#fff;
    margin: 0 .125rem;
}

                    @font-face {
                        font-family: 'Helvetica';
                        src: local('Helvetica'), url(${Helvetica}) format('opentype');
                    }

                    @font-face {
                        font-family: 'Helvetica';
                        font-weight: 900;
                        src: local('Helvetica'), url(${HelveticaBold}) format('opentype');
                    }

                    @font-face {
                        font-family: 'Helvetica';
                        font-weight: 900;
                        src: local('Helvetica'), url(${HelveticaBlack}) format('opentype');
                    }

                    body {
                        font-family: Helvetica, Arial, sans-serif;
                    }

                    .home{
                        height: 100vh;
                        background: url(${background});
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }

                    .animatedLogo{
                        width: 100%;
                        margin: auto;
                    }

                    .contain{
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        margin: auto;
                    }

                    .navbar-toggler-icon {
                        background-image: url(${list}) !important;
                    }

                    .dropdown-toggle::after {
                        display: none !important; 
                    }

                    .nav-link{
                        color: white;
                    }

                    .image{
                        margin: auto;
                        width: 14vw;
                        height: 3.8vw;
                        margin-bottom: 0;
                    }

                    .what_we_do{

                    }

                    .contains2{
                        width: 70%;
                        padding: 5vh;
                        margin: auto;
                    }

                    .why_join_us{
                        min-height: 30vh;
                        margin: auto;
                        display: flex;
                        justify-content: center;

                    }

                    .about_us{
                        min-height: 40vh;
                        margin: auto;
                        display: flex;
                        justify-content: center;
                    }

                    .heading{
                        font-weight: bold;
                        font-size: 4vh;
                        color: #014262;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .cube{
                        height: 6vh;
                    }

                    .footer{
                        background: #1F2023;
                    }

                    .footer-text{
                        text-align: start;
                        color:#cfc6c6;
                        margin: 1px;
                    }

                    .policy-container{
                        display: flex;
                        flex-direction:row;
                        gap:20px;
                        justify-content: space-evenly;
                        paddingTop:40px;
                    }

                    .contact-us{
                        text-align: start;
                        font-weight: bold;
                        color: white;
                    }

                    .nav-styles{
                        opacity: 0.8;
                        background: #004a6d;
                        width: 100%;
                        margin: auto;
                        justify-content: space-around;
                    }

                    .hexagon {
                        position: relative;
                        width: 100%;
                        height: 75vh;
                        margin: auto;
                        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
                      }
                      
                      .vertex {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 40px;
                        height: 40px;
                        background-color: white;
                        border-radius: 50%;
                        transform: translate(-20px, -20px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        color: #333;
                        border-radius: 50%;
                        border: 5px solid #014262;
                        border-bottom: 1px solid #014262;
                      }
                      
                      .vertex:nth-of-type(1) {
                        top: 18%;
                        left: 50%;
                      }
                      .vertex:nth-of-type(2) {
                        top: 38%;
                        left: 89%;
                      }
                      .vertex:nth-of-type(3) {
                        top: 66%;
                        left: 89%;
                      }
                      .vertex:nth-of-type(4) {
                        top: 86%;
                        left: 50%;
                      }
                      .vertex:nth-of-type(5) {
                        top: 66%;
                        left: 10%;
                      }
                      .vertex:nth-of-type(6) {
                        top: 38%;
                        left: 10%;
                      }
                    
                      .border{
                        background-color: black;
                      }

                      .game{
                        background: url('${icon7}');
                        cursor: pointer;
                        height: 0;
                        padding-bottom: 100%;
                        width: 100%;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                      }
                      
                      .game-container{
                        display: flex;

                      }

                      .game-block1{
                        background: red;
                      }

                      .game-block2{
                        background: red;
                      }

                      .game-block3{
                        background: red;
                      }

                    @media only screen and (max-width: 992px) {
                        .contain{
                            width: 100%;
                        }

                        .image{
                            height: 6vh;
                            width: auto;
                        }

                        .footer-text{
                            text-align: center;
                        }

                        .contact-us{
                            text-align: center;
                        }

                        .imf{
                            align-items: center;
                            display: flex;
                            height: 4vh
                        }

                        .contains2{
                            width: 90%;
                        }

                    }

                    @media only screen and (max-width: 600px) {
                        .nav-styles{
                            width: 100%;
                        }

                        .contains2{
                            width: 95%;
                        }
                    }
                `}
            </style>

            <div>
                <Stack style={{ width: "100%" }}>
                    <div className="home" id="home">
                        <Navbar
                            fixed="top"
                            variant="dark"
                            expand="lg"
                            className="nav-styles"
                        >
                            <Container style={{ margin: "0", width: "100%" }}>
                                <Navbar.Brand href="#home">
                                    <img src={logo} className={`image`} />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link
                                            style={{ color: "white" }}
                                            onClick={() => scrollTo("home")}
                                        >
                                            {t("home")}
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{ color: "white" }}
                                            onClick={() =>
                                                scrollTo("what_we_do")
                                            }
                                        >
                                            {t("whatWeDo")}
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{ color: "white" }}
                                            onClick={() =>
                                                scrollTo("why_join_us", true)
                                            }
                                        >
                                            {t("whyJoinUs")}
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{ color: "white" }}
                                            onClick={() => scrollTo("about_us")}
                                        >
                                            {t("aboutUs")}
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{ color: "white" }}
                                            href={`http://${hostlink}/payment.html`}
                                        >
                                            {t("joinUs")}
                                        </Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                                <Navbar.Collapse className="justify-content-end">
                                    <Dropdown
                                        style={{ marginRight: 15 }}
                                        className="justify-content-end"
                                    >
                                        <Dropdown.Toggle
                                            // variant="success"
                                            style={{
                                                background: "transparent",
                                                border: "2px solid white",
                                            }}
                                            id="dropdown-basic"
                                        >
                                            <div>
                                                <img
                                                    className="language-icon"
                                                    src={languageIcon}
                                                />
                                                <img
                                                    className="language-caret"
                                                    src={dropdownCaret}
                                                />
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() =>
                                                    handleLanguageChange("en")
                                                }
                                            >
                                                English
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() =>
                                                    handleLanguageChange("gr")
                                                }
                                            >
                                                Ελληνικά
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown className="justify-content-end">
                                        <Dropdown.Toggle
                                            variant="success"
                                            style={{
                                                background: "transparent",
                                                border: "2px solid white",
                                            }}
                                            id="dropdown-basic"
                                        >
                                            {t("login")}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                href={`http://${hostlink}/admin.html`}
                                            >
                                                {t("admin")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href={`http://${hostlink}/parentLogin.html`}
                                            >
                                                {t("parent")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href={`http://${hostlink}/student.html`}
                                            >
                                                {t("student")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href={`http://${hostlink}/teacher.html`}
                                            >
                                                {t("teacher")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Navbar.Collapse>
                                {/* <Navbar.Collapse className="justify-content-end">
                                    
                                </Navbar.Collapse> */}
                            </Container>
                        </Navbar>
                        <div className="contain">
                            <img className="animatedLogo" src={logo} />
                        </div>
                    </div>
                    <div className="what_we_do" id="what_we_do">
                        <div className="contains2" style={{ width: "80%" }}>
                            <Row
                                className="justify-content-md-center"
                                style={{ minHeight: "60vh" }}
                            >
                                <Col sm={6}>
                                    <div
                                        onClick={(e) => handleShowGame(e)}
                                        className="game"
                                    >
                                        <div className="game-container">
                                            <div className="game-block1"></div>
                                            <div className="game-block2"></div>
                                            <div className="game-block3"></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ margin: "auto" }}>
                                    <h2 className="heading">
                                        <span>
                                            <img
                                                src={cube}
                                                className={`cube`}
                                            />
                                        </span>
                                        {t("whatWeDo")}
                                    </h2>
                                    {t("whatWeDoDescription")}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="why_join_us" id="why_join_us">
                        <div
                            className="contains2"
                            style={{ paddingBottom: "0", marginBottom: "0" }}
                        >
                            <Col className="justify-content-md">
                                <Row style={{ height: "33%" }}>
                                    <h2 className="heading">
                                        {t("whyJoinUs")}
                                    </h2>
                                </Row>
                                <Row style={{ height: "33%" }}></Row>
                                <Row style={{ height: "33%" }}>
                                    {t("whyJoinUsDescription")}
                                </Row>
                            </Col>
                        </div>
                    </div>

                    <div className="">
                        <div className="contains2">
                            {/* <Row xs={1} md={2} xl={3} className="g-4">
                                <Col className="d-flex align-items-stretch">
                                    <Card style={{ background: "#2f7b91" }}>
                                        <Card.Body>
                                            <Card.Title
                                                style={{
                                                    textAlign: "center",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("teachersTitle")}
                                            </Card.Title>
                                            <Card.Text
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                 {t("teachersDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img variant="top" src={img1} />
                                        <Card.Body>
                                            <Card.Text
                                                style={{ color: "white" }}
                                            >
                                                {t("teachersDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="d-flex align-items-stretch">
                                    <Card style={{ background: "#2f7b91" }}>
                                        <Card.Body>
                                            <Card.Title
                                                style={{
                                                    textAlign: "center",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("parentsTitle")}
                                            </Card.Title>
                                            <Card.Text
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Self Placed
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img variant="top" src={img2} />
                                        <Card.Body>
                                            <Card.Text
                                                style={{ color: "white" }}
                                            >
                                                {t("parentsDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="d-flex align-items-stretch">
                                    <Card style={{ background: "#2f7b91" }}>
                                        <Card.Body>
                                            <Card.Title
                                                style={{
                                                    textAlign: "center",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("kidsTitle")}
                                            </Card.Title>
                                            <Card.Text
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Play and Learn
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img variant="top" src={img3} />
                                        <Card.Body>
                                            <Card.Text
                                                style={{ color: "white" }}
                                            >
                                                {t("kidsDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> */}
                            <Row xs={1} md={2} xl={3} className="g-4">
                                <Col className="d-flex align-items-stretch">
                                    <Card style={{ background: "#2f7b91" }}>
                                        <Card.Body>
                                            <Card.Title
                                                style={{
                                                    textAlign: "center",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("teachersTitle")}
                                            </Card.Title>
                                            <Card.Text
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {t("teachersDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img variant="top" src={img1} />
                                        <Card.Body>
                                            <Card.Text
                                                style={{ color: "white" }}
                                            >
                                                {t("teachersFullDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="d-flex align-items-stretch">
                                    <Card style={{ background: "#2f7b91" }}>
                                        <Card.Body>
                                            <Card.Title
                                                style={{
                                                    textAlign: "center",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("parentsTitle")}
                                            </Card.Title>
                                            <Card.Text
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {t("parentsDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img variant="top" src={img2} />
                                        <Card.Body>
                                            <Card.Text
                                                style={{ color: "white" }}
                                            >
                                                {t("parentsFullDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="d-flex align-items-stretch">
                                    <Card style={{ background: "#2f7b91" }}>
                                        <Card.Body>
                                            <Card.Title
                                                style={{
                                                    textAlign: "center",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("kidsTitle")}
                                            </Card.Title>
                                            <Card.Text
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {t("kidsDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img variant="top" src={img3} />
                                        <Card.Body>
                                            <Card.Text
                                                style={{ color: "white" }}
                                            >
                                                {t("kidsFullDescription")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="about_us" id="about_us">
                        <div className="contains2">
                            <Col className="justify-content-md">
                                <Row style={{ height: "33%" }}>
                                    <h2 className="heading">
                                        <span>
                                            <img
                                                src={cube}
                                                className={`cube`}
                                            />
                                        </span>
                                        {t("aboutUs")}
                                    </h2>
                                </Row>
                                <Row style={{ height: "33%" }}></Row>
                                <Row style={{ height: "33%" }}>
                                    {t("aboutUsDescription")}
                                </Row>
                            </Col>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="contains2" style={{ width: "96%" }}>
                            <Row className="justify-content-md-center">
                                <Col sm={3}>
                                    <img
                                        src={logo}
                                        className={`image imf`}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                                <Col sm={3}>
                                    <h5 className="contact-us">
                                        {t("contactUs")}
                                    </h5>
                                    <p className="footer-text">
                                        contact-us@schoolcode.uk
                                    </p>
                                    <p className="footer-text">
                                        www.schoolcode.uk
                                    </p>
                                </Col>
                                <Col sm={6} md={4}>
                                    <h5 className="contact-us">
                                        {t("addressTitle")}
                                    </h5>
                                    <p className="footer-text">
                                        {t("address")}
                                    </p>
                                </Col>
                            </Row>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <p
                                    className="footer-text"
                                    style={{
                                        textAlign: "center ",
                                        paddingTop: "30px",
                                    }}
                                >
                                    {t("footerPrompt")}
                                </p>
                                <div className="policy-container">
                                    <a
                                        style={{
                                            color: "white",
                                            marginRight: "20px",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => showCookiePolicy()}
                                    >
                                        {t("cookiePolicy")}
                                    </a>
                                    <a
                                        style={{
                                            color: "white",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => showPrivacyPolicy()}
                                    >
                                        {t("privacyPolicy")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Stack>

                <>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{modalHeading}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "60vh" }}>
                            {loading && (
                                <Spinner
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            )}
                            {showCookPolicy && (
                                <iframe
                                    src="https://app.termly.io/document/cookie-policy/215f780a-fb69-45a0-8c04-ebc1a78265e3"
                                    onLoad={() => showLoading(false)}
                                    style={{ width: "100%", height: "100%" }}
                                ></iframe>
                            )}
                            {showPrivPolicy && (
                                <iframe
                                    src="https://app.termly.io/document/privacy-policy/7761d1fe-45ba-46f7-9694-6e5505efc217"
                                    onLoad={() => showLoading(false)}
                                    style={{ width: "100%", height: "100%" }}
                                ></iframe>
                            )}
                            {showModalContent && modalContent}
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </>

                <>
                    <Modal
                        fullscreen={true}
                        show={showGame}
                        onHide={handleCloseGame}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Let's Play</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {loading && (
                                <Spinner
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            )}

                            <iframe
                                src={
                                    "https://dev.schoolcode.uk/demo.html?project_file=" +
                                    gameIds[gameSelected]
                                }
                                onLoad={() => showLoading(false)}
                                style={{ height: "100%", width: "100%" }}
                            ></iframe>
                        </Modal.Body>
                    </Modal>
                </>
            </div>
        </>
    );
}

const appTarget = document.createElement("div");
document.body.appendChild(appTarget);
ReactDOM.render(<LandingPage />, appTarget);
